import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeader = _resolveComponent("TableHeader")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableFooter = _resolveComponent("TableFooter")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableBody, null, {
      header: _withCtx(() => [
        _createVNode(_component_TableHeader, {
          "can-collapsed": $setup.likeSearchModel.conditionItems &&
          $setup.likeSearchModel.conditionItems.length !== 0
          ,
          "search-model": $setup.likeSearchModel.conditionItems,
          "default-collapsed-state": false,
          title: "表格查询",
          onDoSearch: $setup.doSearch,
          onResetSearch: $setup.resetsearch,
          onGetSelect: $setup.getSelect
        }, null, 8 /* PROPS */, ["can-collapsed", "search-model"])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          ref: "tableRef",
          data: $setup.dataList,
          "header-cell-style": $setup.tableConfig.headerCellStyle,
          size: $setup.tableConfig.size,
          stripe: $setup.tableConfig.stripe,
          border: $setup.tableConfig.border,
          height: $setup.tableConfig.height,
          "show-summary": "",
          "summary-method": $setup.getSummaries
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "序号",
              prop: "customer_id"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "客户姓名",
              prop: "customer.name",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "代理名称",
              prop: "customer.proxy_user.name",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "交易账户",
              prop: "customer.mt_account",
              "min-width": "110"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_el_text, {
                  class: "mx-1",
                  type: "primary",
                  style: {"cursor":"pointer"},
                  onClick: ($event: any) => ($setup.openOrder(row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.customer.mt_account), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "总交易量",
              prop: "total_volume",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "无返佣交易量",
              prop: "uncommissioned_volume",
              "min-width": "110"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "返佣交易量",
              prop: "commissioned_volume",
              "min-width": "110"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "header-cell-style", "size", "stripe", "border", "height"])), [
          [_directive_loading, $setup.tableLoading]
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_TableFooter, {
          ref: "tableFooter",
          onRefresh: $setup.doSearch,
          onPageChanged: $setup.doSearch
        }, null, 512 /* NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" 交易记录 "),
    _createVNode($setup["TranList"], {
      newTime: $setup.newTime,
      dialogVisible: $setup.dialogVisible,
      newdateRange: $setup.newdateRange,
      userId: $setup.userId,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialogVisible = false))
    }, null, 8 /* PROPS */, ["newTime", "dialogVisible", "newdateRange", "userId"])
  ]))
}